<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3" lg="3"></v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card elevation="8" class="pa-6 mb-6">
          <v-row class="pt-3">
            <v-col cols="12" md="12" lg="12">
              <label class="label-input">{{
                $t("taskAllocation.form.title")
              }}</label>
              <v-text-field v-model="title" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <label class="label-input">{{
                $t("taskAllocation.form.description")
              }}</label>
              <v-textarea
                label="Detail"
                auto-grow
                outlined
                rows="5"
                row-height="15"
                v-model="description"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="6" lg="6">
              <label class="label-input">{{
                $t("taskAllocation.form.start")
              }}</label>
              <DatePicker
                class="date-time"
                style="width: 100%"
                type="date"
                valueType="format"
                slot="activator"
                :append-to-body="true"
                name="founding_date"
                v-model="start_date"
              ></DatePicker>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <label class="label-input">{{
                $t("taskAllocation.form.deadline")
              }}</label>
              <DatePicker
                class="date-time"
                style="width: 100%"
                type="date"
                valueType="format"
                slot="activator"
                :append-to-body="true"
                name="founding_date"
                v-model="end_date"
              ></DatePicker>
            </v-col>

            <v-col cols="12" md="6" lg="6" class="pt-6">
              <label class="label-input">{{
                $t("taskAllocation.form.dept")
              }}</label>
              <v-autocomplete
                filled
                dense
                item-text="name"
                item-value="id"
                outlined
                :items="listDepartment"
                hide-selected
                v-model="department_id"
                @change="fetAssignee(department_id)"
              >
                <!-- :hint="`${server_errors.department_id}`" -->
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="6" class="pt-6">
              <label class="label-input">{{
                $t("taskAllocation.form.assignTo")
              }}</label>
              <v-autocomplete
                filled
                dense
                item-text="name"
                item-value="user_id"
                outlined
                :items="listAssignee"
                hide-selected
                v-model="assignee_id"
              >
                <!-- :hint="`${server_errors.assignee_id}`" -->
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }} {{ data.item.surname }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="pb-4">
              <v-btn
                class="mt-7 btn-create lfont btn"
                color="info"
                @click="saveChange"
              >
                {{ $t("taskAllocation.form.save") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" lg="3"></v-col>
    </v-row>
    <Loading v-if="isLoading" />
  </v-container>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "@/components/Loading";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      title: "",
      start_date: new Date().toISOString().slice(0, 10),
      end_date: "",
      assignee_id: "",
      description: "",
      isLoading: false,
      listDepartment: [],
      listAssignee: [],
      department_id: "",
      assignee_id: "",
      server_errors: {
        title: "",
        start_date: "",
        end_date: "",
        assignee_id: "",
        description: "",
      },
    };
  },
  methods: {
    fetchDepartment() {
      this.isLoading = true;
      this.$axios
        .get(`/employee/list/departments`)
        .then((res) => {
          // console.log("department", res);
          if (res.status === 200) {
            this.isLoading = false;
            this.listDepartment = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    fetAssignee(dept_id) {
      this.isLoading = true;
      this.$axios
        .get(`/employee/find/line/managers/${dept_id}`)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listAssignee = res.data.data;
            // console.log("Assignee", res.data.data);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    saveChange() {
      const items = {
        title: this.title,
        description: this.description,
        start_date: this.start_date,
        end_date: this.end_date,
        assignee_id: this.assignee_id,
      };
      this.$axios
        .post(`/employee/add/todo/list`, items)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.$router
              .push({
                name: "employee_task_allocation.index",
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  created() {
    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    this.end_date = moment(firstDate).format("YYYY-MM-DD");
    this.fetchDepartment();
  },
};
</script>

<style lang="scss" scoped></style>
